<template>
  <div class="merlends refundSlip">
    <!-- 搜索列 -->
    <div class="merch-search">
      <div class="search-box">
        <!-- 退款订单 -->
        <div class="sch-1 sch-2">
          <!-- <div class="sch-title">退款订单:</div> -->
          <el-input
            placeholder="请输入退款订单号"
            v-model="searchData.cpcn_return_id"
          ></el-input>
        </div>
        <!-- 订单号 -->
        <div class="sch-1 sch-2">
          <!-- <div class="sch-title">订单号:</div> -->
          <el-input
            placeholder="请输入订单号"
            v-model="searchData.order_id"
          ></el-input>
        </div>
        <!-- 主订单号 -->
        <div class="sch-1 sch-2">
          <!-- <div class="sch-title">主订单号:</div> -->
          <el-input
            placeholder="请输入主订单号"
            v-model="searchData.group_id"
          ></el-input>
        </div>
        <!-- 退款流水号 -->
        <div class="sch-1 sch-2">
          <!-- <div class="sch-title">退款流水号:</div> -->
          <el-input
            placeholder="请输入退款流水号"
            v-model="searchData.cpcn_return_trade_no"
          ></el-input>
        </div>
        <div class="sch-1 sch-2">
          <!-- <div class="sch-title">会员名称:</div> -->
          <el-input
            placeholder="请输入会员名称"
            v-model="searchData.shop_name"
          ></el-input>
        </div>
        <!-- 共享供应商 -->
        <div class="sch-1 sch-2">
          <!-- <div class="sch-title">共享供应商:</div> -->
          <el-input
            placeholder="请输入共享供应商"
            v-model="searchData.supplier_name"
          ></el-input>
        </div>
        <!-- 销售供应商 -->
        <div class="sch-1 sch-2">
          <!-- <div class="sch-title">销售供应商:</div> -->
          <el-input
            placeholder="请输入销售供应商"
            v-model="searchData.sale_name"
          ></el-input>
        </div>
        <!-- 退款类型 -->
        <div class="sch-1 sch-2">
          <!-- <div class="sch-title">退款类型:</div> -->
          <el-select
            v-model="searchData.refund_type"
            placeholder="选择退款类型"
          >
            <el-option
              v-for="item in refundType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <!-- 退款状态 -->
        <div class="sch-1 sch-2">
          <!-- <div class="sch-title">退款状态:</div> -->
          <el-select v-model="searchData.status_" placeholder="选择退款状态">
            <el-option
              v-for="item in refundStateList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <!-- 退款时间 -->
        <div class="time">
          <div class="sch-1 sch-2 sch-3 inquire">
            <!-- <div class="sch-title">退款时间:</div> -->
            <el-date-picker
              v-model="searchData.orderCreationTime"
              type="datetimerange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
            >
            </el-date-picker>
          </div>
          <div>
            <!-- 搜索 -->
            <div class="e-button">
              <el-button type="primary" size="mini" @click="getReturnList()">
                查询
              </el-button>
              <el-button size="mini" @click="reset()"> 重置 </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="dataTable">
      <el-table :data="tableData1" border style="width: 100%">
        <el-table-column
          align="center"
          fixed
          label="序号"
          type="index"
          width="70"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.index }}</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="date"
          label="退款单号/时间"
          width="200"
          align="center"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.cpcn_return_id }}</p>
            <p>{{ scope.row.create_time }}</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="date"
          label="退款类型"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.refund_type == 0 ? "售后" : "冲货" }}</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="date"
          label="共享供应商"
          width="200"
          align="center"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.supplier_name }}</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="date"
          label="销售供应商"
          width="200"
          align="center"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.sale_name }}</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="date"
          label="退款流水号/时间"
          width="280"
          align="center"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.cpcn_return_trade_no }}</p>
            <p>{{ scope.row.return_pay_create_time }}</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="date"
          label="售后单号/时间"
          width="200"
          align="center"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.after_sales_id }}</p>
            <p>{{ scope.row.after_sales_time }}</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="date"
          label="主订单号"
          width="200"
          align="center"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.group_id }}</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="date"
          label="运费 (元)"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.returned_freight_amount_total }}</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="date"
          label="货款 (元)"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.price_total }}</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="date"
          label="佣金 (元)"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.platform_commission_total }}</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="date"
          label="手续费 (元)"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.platform_handling_fee_total }}</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="date"
          label="退款合计 (元)"
          width="120"
          align="center"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.return_total }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="date" label="状态" width="100" align="center">
          <template slot-scope="scope">
            <p v-if="scope.row.status_ == 10">受理成功</p>
            <p v-if="scope.row.status_ == 20">成功</p>
            <p v-if="scope.row.status_ == 30">失败</p>
            <p v-if="scope.row.status_ == 40">退票</p>
          </template>
        </el-table-column>
        <el-table-column prop="date" label="订单号" width="200" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.order_id }}</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="date"
          label="操作"
          fixed="right"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            <el-button
              @click="toRefundInfo(scope.row)"
              type="text"
              size="small"
              v-if="allJurisdiction.refundSlip125"
              >详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="footer">
      <el-pagination
        background
        layout=" total,prev, pager, next, jumper"
        :page-size="per_page"
        :total="pageTotal"
        :current-page.sync="searchData.page"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const settlement = createNamespacedHelpers("settlement");
export default {
  data() {
    return {
      searchData: {
        after_order_id: "", //售后单
        order_id: "", //订单
        group_id: "", //主订单
        cpcn_return_trade_no: "", //退款流水
        shop_name: "", //会员名
        supplier_name: "", //供应商
        sale_name: "", //销售名
        refund_type: "", //退款类型
        status_: "", //退款状态
        cpcn_return_id: "", //退款单号
        create_time: [], //时间
        orderCreationTime: [],
      },
      refundTypeList: [],
      refundStateList: [],
      tableData: [{}],
      tableData1: [],
      currentPage1: 1,
      per_page: 0,
      pageTotal: 0,
      refundType: [
        {
          value: "",
          label: "全部",
        },
        {
          value: 0,
          label: "售后",
        },
        {
          value: 1,
          label: "冲货",
        },
      ],
      refundStateList: [
        {
          value: "",
          label: "全部",
        },
        {
          value: 10,
          label: "受理成功",
        },
        {
          value: 20,
          label: "成功",
        },
        {
          value: 30,
          label: "失败",
        },
        {
          value: 40,
          label: "退票",
        },
      ],
    };
  },

  computed: {
    ...commonIndex.mapState([
      "AreaList",
      "mainHright",
      "allJurisdiction",
      "detailsSeach",
    ]),
    ...settlement.mapState(["returnList", "returnTotal", "returnPage"]),
  },

  created() {
    let router = this.$router.history.current.path.substr(1);
    if (this.detailsSeach[router]) {
      this.searchData = this.detailsSeach[router];
      // this.cascaderValue=this.detailsSeach[router].cascaderValue
    }
    this.inquire();
  },

  methods: {
    ...settlement.mapActions(["returnOrderList"]),
    ...commonIndex.mapMutations(["changeSeach"]),
    toRefundInfo(item) {
      this.$router.push({
        path: "/refundInfo",
        query: { id: item.after_sales_id },
      });
    },
    // 换页
    handleCurrentChange(item) {
      this.$set(this.searchData, "page", item);
      this.inquire();
    },
    // 获取退款单列表
    async inquire() {
      this.searchData.create_time = [];
      if (this.searchData.orderCreationTime) {
        this.searchData.orderCreationTime.forEach((v, i) => {
          this.searchData.create_time.push(this.dateFormat(v));
        });
        // this.searchData.create_time=this.tableData.orderCreationTime
      }
      let data = await this.returnOrderList(this.searchData);

      let router = this.$router.history.current.path.substr(1);
      let save = { ...this.searchData };
      // save.orderCreationTime=this.orderCreationTime
      save.router = router;
      this.changeSeach(save);

      data.data.data.forEach((v, i) => {
        let index = (data.data.current_page - 1) * data.data.per_page + i + 1;
        this.$set(v, "index", index);
      });
      this.tableData1 = data.data.data;
      this.pageTotal = data.data.total;
      this.per_page = data.data.per_page;
    },
    // 重置
    reset() {
      this.searchData.after_order_id = "";
      this.searchData.order_id = "";
      this.searchData.group_id = "";
      this.searchData.cpcn_return_trade_no = "";
      this.searchData.shop_name = "";
      this.searchData.supplier_name = "";
      this.searchData.sale_name = "";
      this.searchData.cpcn_return_id = "";
      this.searchData.refund_type = "";
      this.searchData.status_ = "";
      this.searchData.shop_name = "";
      this.searchData.create_time = [];
      this.searchData.orderCreationTime = [];
    },
    //
    getReturnList() {
      this.searchData.page = 1;
      this.currentPage1 = 1;
      this.inquire();
    },
    // 时间格式转化
    dateFormat(dateData) {
      var date = new Date(dateData);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      const time = y + "-" + m + "-" + d;
      return time;
    },
  },
};
</script>

<style lang="scss" scoped>
.refundSlip {
  // 搜索行样式
  .merch-search {
    margin-top: 1%;
    width: 100%;
    .search-box {
      display: flex;
      flex-wrap: wrap;
    }
    .sch-1 {
      margin-top: 10px;
      width: 18%;
      min-width: 200px;
      display: flex;

      .sch-title {
        width: 100px;
        min-width: 100px;
        height: 100%;
        font-size: 13px;
        background-color: #f5f7fa;
        color: #909399;
        border: 1px solid #dcdfe6;
        border-right: none;
        border-radius: 4px 0px 0px 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 0px 0px 5px !important;
      }
    }
    .sch-2 {
      margin-right: 2%;
    }
    .time {
      width: 38%;
      display: flex;
      justify-content: space-between;
      .inquire {
        flex: 1;
      }
      .e-button {
        button {
          padding: 12px 20px;
          margin-top: 10px;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }

  .dataTable {
    margin-top: 20px;
  }

  .footer {
    text-align: right;
    margin-top: 20px;
  }
}
::v-deep .el-date-editor .el-range-separator {
  width: 40px;
}
</style>
